import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [isSentRequest, setIsSentRequest] = useState(false);
  const [IsFailed, setIsFailed] = useState(false);
  const [IsSuccessed, setIsSuccessed] = useState(false);
  const [IsSent, setIsSent] = useState(false);
  const [pleasFille, setpleasFille] = useState(false);

  const form = useRef();
  const { t } = useTranslation();

  const sendEmail = (e) => {
    setpleasFille(false);

    var input = document.getElementById('input1');
    var input2 = document.getElementById('input2');
    var input3 = document.getElementById('input3');
    if(input.validity.valid && input2.validity.valid && input3.validity.valid  ){
      setIsSentRequest(true);
      setIsSuccessed(false);
      setIsFailed(false);
      // e.preventDefault();
      if (!IsSent) {
        emailjs
          .sendForm(
            "service_9y7n2nq",
            "template_gaipzgs",
            form.current,
            "xEwbPm-gv9PbTIh57"
          )
          .then(
            (result) => {
              setIsSuccessed(true);
              setIsSent(true);
            },
            (error) => {
              setIsSentRequest(false);
              setIsFailed(true);
            }
          );
      } else {
        setIsSuccessed(true);
      }
    }else{
      setpleasFille(true);

    }



  };
  return (
    <React.Fragment>

      <section
        className="section section-contact bg-theme tc-light"
        id="contact"
      >
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animate__animated animate__fadeInUp animate__delay-1s"
              data-animate="fadeInUp"
              data-delay=".1"
              title="تواصل معنا"
            >
              تواصل معنا
            </h2>
            <p className="animate__animated animate__fadeInUp animate__delay-2s" data-animate="fadeInUp" data-delay=".2">
              اترك رسالة لنا و سنعيد التواصل معك
            </p>
          </div>
          {/* .section-head @e */}
          {/* Block @s */}
          <div className="nk-block block-contact">
            <div className="row justify-content-center text-center">
              <div className="col-xl-8 col-lg-10">
                <ul className="contact-list contact-list-s1 flex-wrap flex-md-nowrap pdb-l">
                  <li
                    className="animate__animated animate__fadeInUp animate__delay-3s"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    <em className="contact-icon fas fa-phone" />
                    <div className="contact-text ltr">
                      <span>+966 55 569 0900</span>
                    </div>
                  </li>{" "}
                  <li
                    className="animate__animated animate__fadeInUp animate__delay-3s"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    <em className="contact-icon fas fa-phone" />
                    <div className="contact-text ltr">
                      <span>+966 59 989 9207</span>
                    </div>
                  </li>
                  <li
                    className="animate__animated animate__fadeInUp animate__delay-4s"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    <em className="contact-icon fas fa-envelope" />
                    <div className="contact-text">
                      <span>s.alshehri@tkrights.com</span>
                    </div>
                  </li>
                </ul>
              </div>
              {/* .col */}
              <div className="col-xl-8 col-lg-10">
                <div className="contact-wrap p-0">
                  <form
                    ref={form} id="tessst"
                    // id="contact-form-01"
                    className="contact-form nk-form-submit needs-validation"
                  >
                    <div
                      className="field-item field-item-center animate__animated animate__fadeInUp animate__delay-6s"
                      data-animate="fadeInUp"
                      data-delay=".6"
                    >
                      <input
                        name="Name"
                        type="text"
                        className="input-line required" id="input1" required
                      />
                      <label className="field-label field-label-line">
                        الاسم
                      </label>
                    </div>
                    <div
                      className="field-item field-item-center animate__animated animate__fadeInUp animate__delay-7s"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <input
                        name="Email"
                        type="email" id="input2" required
                        className="input-line input-line-center required email"
                      />
                      <label className="field-label field-label-line">
                        البريد الالكتروني
                      </label>
                    </div>
                    <div
                      className="field-item field-item-center animate__animated animate__fadeInUp animate__delay-8s"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <textarea
                        name="Message" id="input3" required 
                        className="input-line input-line-center input-textarea required"
                        defaultValue={""}
                      />
                      <label className="field-label field-label-line">
                        الرسالة
                      </label>
                    </div>

                    <div
                      className="field-wrap animate__animated animate__fadeInUp animate__delay-9s"
                      data-animate="fadeInUp"
                      data-delay=".9"
                    >
                      <div style={{ display: IsSuccessed ? "block" : "none" }}>
                        <div className="alert d-flex align-items-center justify-content-center ">
                          <div>تم إرسال الرسالة</div>
                        </div>
                      </div>
                      <div style={{ display: pleasFille ? "block" : "none" }}>
                        <div className="alert d-flex align-items-center justify-content-center ">
                          <div>الرجاء التأكد من كتابة الايميل بشكل صحيح و اكمال الخانات</div>
                        </div>
                      </div>

                      <div style={{ display: IsFailed ? "block" : "none" }}>
                        <div
                          className="alert d-flex align-items-center justify-content-center "
                          role="alert"
                        >
                          <div>فشل ارسال الرسالة , الرجاء المحاولة لاحقا </div>
                        </div>
                      </div>
                      <div onClick={() => sendEmail()}
                        className="btn btn-round btn-lg btn-grad"
                        disabled={isSentRequest && !IsSuccessed ? true : false}
                      >
                        {isSentRequest && !IsSuccessed ? (
                          <div> جاري الارسال... </div>
                        ) : (
                          <span>ارسال</span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* .col */}
            </div>
            {/* .row */}
          </div>
          {/* .block @e */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default Contact;
