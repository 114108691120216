import React from "react";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="section mask-c-blend-dark bg-theme-dark-alt tc-light ov-h" id="about">
        <div className="container">
          {/* Block @s */}
          <div className="nk-block nk-block-text-wrap">
            <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
              <div className="col-mb-9 col-sm-7 col-md-6 col-lg-5 order-md-last">
                <div
                  className="nk-block-img animate__animated animate__fadeInUp animate__delay-1s"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  <img src="images/light/gfx-z-a.png" alt="app" />
                </div>
              </div>
              <div className="col-sm-10 col-md-6 text-center text-md-start">
                <div className="nk-block-text">
                  <h2
                    className="title animate__animated animate__fadeInUp animate__delay-2s"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    ماذا نقدم في حقوق تقنية ؟
                  </h2>
                  <p
                    className="lead animate__animated animate__fadeInUp animate__delay-3s"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    نحن نقدم حلولًا تقنية متطورة لمؤسسات القانون تساعدهم على
                    تحسين جودة خدماتهم وزيادة الكفاءة في العمل، ونسعى دائمًا
                    لتلبية احتياجات عملائنا بأحدث التقنيات والمنتجات الذكية.
                    فنحن نؤمن بأن الابتكار التقني هو المفتاح الأساسي لنجاح
                    المؤسسات، ولذلك نعمل جاهدين لتقديم حلول فريدة من نوعها
                    وفعالة لمساعدة عملائنا في تحقيق أهدافهم
                  </p>
                </div>
              </div>
            </div>
            {/* .row */}
          </div>
          {/* .block @e */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default About;
