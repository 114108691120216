import React from "react";

import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>

      <section className="section bg-theme tc-light pb-0" id="faq">
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animate__animated animate__fadeInUp animate__delay-1s"
              data-animate="fadeInUp"
              data-delay=".1"
              title="الأسئلة الشائعة"
            >
              الأسئلة الشائعة
            </h2>
          </div>
          {/* .section-head @e */}
          {/* Block @s */}
          <div className="nk-block overflow-hidden">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div
                  className="tab-content animate__animated animate__fadeInUp animate__delay-4s"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <div
                    className="tab-pane fade show active"
                    id="general-questions-19"
                  >
                    <div
                      className="accordion accordion-s1 accordion-faq"
                      id="faq-67"
                    >
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-67-1"
                        >
                          {" "}
                          ماهي خدمات حقوق تقنية ؟
                          <span className="accordion-icon accordion-icon-s2" />
                        </h5>
                        <div
                          id="faq-67-1"
                          className="collapse show"
                          data-bs-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              نحن نقدم خدمات تقنية للمكاتب القانونية والمحامين،
                              بما في ذلك تصميم مواقع الويب، وحلول تحسين محركات
                              البحث، وتطوير التطبيقات المخصصة للشركة.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-67-2"
                        >
                          {" "}
                          هل يمكن تخصيص الخدمات وفقا لاحتياجاتي؟
                          <span className="accordion-icon accordion-icon-s2" />
                        </h5>
                        <div
                          id="faq-67-2"
                          className="collapse"
                          data-bs-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              نعم، يمكننا تخصيص حلولنا لتلبية احتياجات عملائنا
                              الفريدة وتحقيق أهدافهم.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-67-3"
                        >
                          {" "}
                          هل تقدمون دعمًا فنيًا مستمرًا؟
                          <span className="accordion-icon accordion-icon-s2" />
                        </h5>
                        <div
                          id="faq-67-3"
                          className="collapse"
                          data-bs-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              نعم، نحن نقدم دعمًا فنيًا مستمرًا لعملائنا عبر
                              البريد الإلكتروني والهاتف.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-67-4"
                        >
                          {" "}
                          هل تضمنون جودة الخدمات التي تقدمونها ؟
                          <span className="accordion-icon accordion-icon-s2" />
                        </h5>
                        <div
                          id="faq-67-4"
                          className="collapse"
                          data-bs-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              نعم، نحن نعمل بجد لتقديم أفضل جودة لعملائنا ونضمن
                              جودة عالية لجميع الخدمات التي نقدمها.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .col */}
            </div>
            {/* .row */}
          </div>
          {/* .block @e */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default FAQ;
