import React from "react";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>


      <section className="section bg-theme-dark-alt tc-light" id="principles">
        <div className="container">
          {/* Block @s */}
          <div className="nk-block nk-block-features-s2">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-lg-5">
                <div className="gfx py-4">
                  <img
                    src="images/gfx/gfx-a.png"
                    className="animate__animated animate__fadeInUp animate__delay-1s"
                    data-animate="fadeInUp"
                    data-delay=".3"
                    alt="gfx"
                  />
                </div>
              </div>
              {/* .col */}
              <div className="col-lg-7">
                {/* Section Head @s */}
                <div
                  className="section-head animate__animated animate__fadeInUp animate__delay-1s"
                  data-animate="fadeInUp"
                  data-delay=".45"
                >
                  <h2 className="title">مبادئنا</h2>
                  <p>
                    في حقوق تقنية, نحرص دائمًا على الحفاظ على معايير عالية في
                    جميع مراحل العمل، من التصميم والتطوير إلى تقديم الخدمات
                    والدعم. ونسعى جاهدين لتحسين وتطوير عملنا بشكل مستمر لتلبية
                    احتياجات وتوقعات عملائنا، وذلك باستخدام أفضل الممارسات
                    والتقنيات المتاحة في صناعتنا.
                  </p>
                </div>
                {/* .section-head @e */}
                <div
                  className="features-list me-4 mgb-m30 animate__animated animate__fadeInUp animate__delay-1s"
                  data-animate="fadeInUp"
                  data-delay=".55"
                >
                  <div className="feature feature-s2">
                    <div className="feature-icon dot">
                      <em className="icon ikon ikon-shiled-alt" />
                    </div>
                    <div className="feature-text">
                      <h5 className="title title-sm">جودة عالية</h5>
                      <p>
                        نحرص على استخدام أحدث التقنيات والممارسات لتقديم منتجات
                        وخدمات عالية الجودة تلبي احتياجات العملاء وتتجاوز
                        توقعاتهم.
                      </p>
                    </div>
                  </div>
                  <div className="feature feature-s2">
                    <div className="feature-icon dot">
                      <em className="icon ikon ikon-user" />
                    </div>
                    <div className="feature-text">
                      <h5 className="title title-sm">سرعة التطوير</h5>
                      <p>
                        نحرص على تسريع عملية التطوير والابتكار لتقديم منتجات
                        وخدمات جديدة بأفضل الخصائص والوظائف في أقصر وقت ممكن.
                      </p>
                    </div>
                  </div>
                  <div className="feature feature-s2">
                    <div className="feature-icon dot">
                      <em className="icon ikon ikon-data-server" />
                    </div>
                    <div className="feature-text">
                      <h5 className="title title-sm">دعم مستمر</h5>
                      <p>
                        نعمل جاهدين على توفير الدعم المستمر لعملائنا، سواءً في
                        صيانة المنتجات وتحديثاتها أو توفير الدعم الفني والتدريب
                        المستمر، لضمان حصولهم على تجربة استخدام مريحة وناجحة.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* .col */}
            </div>
            {/* .row */}
          </div>
          {/* .block @e */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default About;
