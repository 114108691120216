import React from "react";
import { useTranslation } from "react-i18next";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Idea from "./components/Idea";
import Features from "./components/Features";
import Principles from "./components/Principles";
import Header from "./components/Header";

import "./Home.css";

class Home extends React.Component {
  componentDidMount() {
    const script2 = document.createElement("script");
    script2.src = "./assets2/js/jquery.bundle.js?ver=210";
    script2.async = true;
    script2.onload = () => this.scriptLoaded();

    document.body.appendChild(script2);

    const script = document.createElement("script");
    script.src = "/assets2/js/scripts.js?ver=210";
    script.async = true;
    script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <main className="nk-pages">
          <About />
          <Features />
          <Idea />

          {/* <Principles /> */}

          <FAQ />
          <Contact />
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
