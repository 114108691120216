import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("en");

  const nav = (f) => {
    document.getElementById(f).scrollIntoView();

    const element2 = document.getElementsByClassName("header-navbar");
    console.log(element2)
    element2[0].classList.remove("menu-shown");

    document.body.classList.remove("overlay-menu-shown");
  };
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const mobile = (f) => {
    // delay(200).then(() => {
    document.body.classList.add("overlay-menu-shown");
    const element = document.getElementById("header-navbar");
    element.classList.add("menu-mobile");
    const element2 = document.getElementById("header-navbar");

    element2.classList.add("menu-shown");
    // });
  };
  return (
    <React.Fragment>
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
        id="header"
      >
        {/* Header @s */}
        <div className="header-main">
          <div className="header-container container">
            <div className="header-wrap">
              {/* Logo @s */}
              <div
                className="header-logo logo animate__animated animate__fadeInUp animate__delay-6s"
                data-animate="fadeInDown"
                data-delay=".65"
              >
                <a href="/" className="logo-link">
                  <img
                    className="logo-dark"
                    src="./assets/images/logo.png"
                    srcSet="./assets/images/logo.png"
                    alt="logo"
                  />
                  <img
                    className="logo-light"
                    src="./assets/images/logo.png"
                    srcSet="./assets/images/logo.png"
                    alt="logo"
                  />
                </a>
              </div>
              {/* Menu Toogle @s */}
              <div className="header-nav-toggle">
                <a
                  href="#"
                  className="navbar-toggle "
                  data-menu-toggle="example-menu-04"
                >
                  <div className="toggle-line">
                    <span></span>
                  </div>
                </a>
              </div>
              {/* Menu @s */}
              <div
                className="header-navbar header-navbar-s1"
                id="header-navbar"
              >
                <nav className="header-menu" id="example-menu-04">
                  <ul
                    className="menu menu-s2 animate__animated animate__fadeInUp animate__delay-7s"
                    data-animate="fadeInDown"
                    data-delay=".75"
                  >
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#">
                        الرئيسية
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        className="menu-link nav-link pointer"
                        
                        onClick={() => nav("about")}
                      >
                        عن حقوق تقنية
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        className="menu-link nav-link pointer"
                        
                        onClick={() => nav("principles")}
                      >
                        مبادئنا
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        className="menu-link nav-link pointer"
                        
                        onClick={() => nav("features")}
                      >
                        مميزاتنا
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        className="menu-link nav-link pointer "
                        
                        onClick={() => nav("faq")}
                      >
                        الاسئلة الشائعة
                      </a>
                    </li>
                  </ul>
                  <ul
                    className="menu-btns animate__animated animate__fadeInUp animate__delay-8s"
                    data-animate="fadeInDown"
                    data-delay=".85"
                  >
                    <li>
                      <a
                        onClick={() => nav("contact")}
                        className="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme btn-round"
                      >
                        <span>تواصل معنا</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* .header-navbar @e */}
            </div>
          </div>
        </div>
        {/* .header-main @e */}
        {/* Banner @s */}
        <div className="header-banner bg-theme-dark">
          <div className="nk-banner">
            <div className="banner banner-mask-fix banner-fs banner-single tc-light">
              <div className="banner-wrap">
                <div className="container">
                  <div className="row align-items-center justify-content-center gutter-vr-30px">
                    <div className="col-lg-6 order-lg-last">
                      <div
                        className="banner-gfx banner-gfx-re-s3 animate__animated animate__fadeInUp animate__delay-1s"
                        data-animate="fadeInUp"
                        data-delay="1.25"
                      >
                        <img src="images/dark/gfx-d-dark.png" alt="header" />
                        <img
                          className="banner-gfx-icon banner-gfx-icon-1"
                          src="images/icons/icon-f-light.png"
                          alt=""
                        />
                        <img
                          className="banner-gfx-icon banner-gfx-icon-2"
                          src="images/icons/icon-f-dark.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="banner-caption wide-auto text-center text-lg-start">
                        <div className="cpn-head mt-0">
                          <h1
                            className="title title-xl-2 title-semibold animate__animated animate__fadeInUp animate__delay-1s"
                            data-animate="fadeInUp"
                            data-delay="1.35"
                          >
                            حقوق تقنية
                          </h1>
                        </div>
                        <div className="cpn-text cpn-text-s1">
                          <p
                            className="lead animate__animated animate__fadeInUp animate__delay-1s"
                            data-animate="fadeInUp"
                            data-delay="1.45"
                          >
                            نقدم أفضل منتجات وحلول تقنية لتمكين المؤسسات في
                            المجال القانوني لنساعدهم في تقديم افضل جودة للعملاء
                          </p>
                        </div>
                        <div className="cpn-btns">
                          <ul
                            className="btn-grp animate__animated animate__fadeInUp animate__delay-1s"
                            data-animate="fadeInUp"
                            data-delay="1.55"
                          >
                            <li>
                              <a
                                href="#contact"
                                className="btn btn-md btn-grad btn-round"
                              >
                                تواصل معنا
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="cpn-social">
                          <ul className="social">
                            <li
                              className="animate__animated animate__fadeInUp animate__delay-1s"
                              data-animate="fadeInUp"
                              data-delay="1.7"
                            >
                              <a href="#">
                                <em className="social-icon fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
              </div>
            </div>
          </div>
          {/* .nk-banner */}
          <div className="nk-ovm mask-c-dark shape-v mask-contain-bottom" />
          {/* Place Particle Js */}
          <div
            id="particles-bg"
            className="particles-container particles-bg"
            data-pt-base="#00c0fa"
            data-pt-base-op=".3"
            data-pt-line="#2b56f5"
            data-pt-line-op=".5"
            data-pt-shape="#00c0fa"
            data-pt-shape-op=".2"
          />
        </div>
        {/* .header-banner @e */}
      </header>
    </React.Fragment>
  );
};
export default Header;
