import React from "react";

import { useTranslation } from "react-i18next";

const Idea = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="section bg-theme-dark-alt tc-light" id="features">
        <div className="container">
          <div className="section-head text-center wide-auto-sm tc-light">
            <div className="section-head-line">
              <span className="line-1" />
              <span className="line-2" />
              <span className="line-3" />
              <span className="line-4" />
              <span className="line-5" />
              <span className="line-6" />
              <span className="line-7" />
              <span className="line-8" />
            </div>
            <h2
              className="title title-s4 animate__animated animate__fadeInUp animate__delay-1s"
              data-animate="fadeInUp"
              data-delay=".1"
              title="مميزاتنا"
            >
              مميزاتنا
            </h2>
          </div>
          {/* Block @s */}
          <div className="nk-block nk-block-text-wrap">
            <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
              <div className="col-mb-9 col-sm-7 col-md-6">
                <div
                  className="nk-block-img animate__animated animate__fadeInUp animate__delay-3s"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <img src="images/dark/gfx-z-c.png" alt="app" />
                </div>
              </div>
              <div className="col-sm-10 col-md-6 col-lg-5">
                <div className="nk-block-text ms-lg-3 ms-xl-0" dir="rtl">
                  <p 
                    className="animate__animated animate__fadeInUp animate__delay-4s"
                    data-animate="fadeInUp"
                    data-delay=".4" 
                  >
                    نحن فريق حقوق تقنية ملتزمون بتقديم أعلى معايير الجودة في خدماتنا. نتميز بالخبرة والاحترافية التي يتمتع بها أعضاء فريقنا المتخصصين في مجال حقوق التكنولوجيا. نحرص على تزويد عملائنا بحلول فريدة ومبتكرة تلبي احتياجاتهم وتساعدهم على تحقيق أهدافهم. نحن نتابع أحدث التطورات التقنية ونضعها في الاعتبار في عملنا، مع التركيز على توفير الكفاءة والفعالية في جميع جوانب العمل. نحن نولي أهمية كبيرة لتفهم تحديات ومتطلبات عملائنا ونعمل بشكل وثيق معهم لضمان تحقيق نتائج ممتازة ورضا كامل لهم.





                  </p>
                  <ul className="list list-dot pdb-r">
                    <li
                      className="animate__animated animate__fadeInUp animate__delay-5s"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      تصميم جذاب - تصاميم تجذب العين وتعزز الهوية البصرية
                    </li>
                    <li
                      className="animate__animated animate__fadeInUp animate__delay-5s"
                      data-animate="fadeInUp"
                      data-delay=".55"
                    >
                      سرعة فائقة - منتجات تعمل بسلاسة وسرعة عالية
                    </li>
                    <li
                      className="animate__animated animate__fadeInUp animate__delay-6s"
                      data-animate="fadeInUp"
                      data-delay=".65"
                    >
                      سهل الاستخدام - واجهات سهلة وبديهية لتجربة استخدام مريحة
                    </li>
                    <li
                      className="animate__animated animate__fadeInUp animate__delay-7s"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      التميز - حلول مخصصة تلبي الاحتياجات الفريدة للعملاء
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* .row */}
          </div>
          {/* .block @e */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default Idea;
