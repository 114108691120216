import React from "react";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <footer className="nk-footer bg-theme-alt section-connect">
        <div className="section section-m pb-0 tc-light ov-h">
          <div className="container py-4">
            {/* Block @s */}
            <div className="nk-block pb-lg-5">
              <div className="row justify-content-center text-center">
                <div className="col-lg-6 col-md-9">
                  <div className="wide-auto-sm section-head section-head-sm pdb-r">
                    <h4
                      className="title title-md animate__animated animate__fadeInUp animate__delay-1s"
                      data-animate="fadeInUp"
                      data-delay=".1"
                    >
                      ابقَ مُطلعًا
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            {/* .block @e */}
          </div>
          <div className="nk-ovm shape-contain shape-center-top shape-p" />
        </div>
        <div className="section section-footer section-s tc-light bg-transparent">
          <div className="container">
            {/* Block @s */}
            <div className="nk-block block-footer">
              <div className="row">
                <div className="col">
                  <div className="wgs wgs-text text-center mb-3">
                    <ul className="social pdb-m justify-content-center">
                      <li>
                        <a href="https://twitter.com/TeK_Rights">
                          <em className="social-icon fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                    <div className="copyright-text copyright-text-s3 pdt-m">
                      <p>
                        <span className="d-sm-block">
                          جميع الحقوق محفوظة © 2023, حقوق تقنية.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* .block @e */}
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
